import { IToken } from './interface';

const isAuth = {
    isAuthenticated: false,
    authenticate(cb: any) {
      const llStorage = window.localStorage;
      const tk: IToken = JSON.parse(llStorage.getItem('atpe') as string);
      isAuth.isAuthenticated = (tk && tk.token) ? true : false;
      setTimeout(cb, 100);
    },

    getIsAuthenticated() {
      const llStorage = window.localStorage;
      const tk: IToken = JSON.parse(llStorage.getItem('atpe') as string);
      isAuth.isAuthenticated = (tk && tk.token) ? true : false;
      return isAuth.isAuthenticated;
    },

    signout(cb: any) {
      const llStorage = window.localStorage;
      const locat = window.location;
      isAuth.isAuthenticated = false;
      llStorage.removeItem('atpe');
      locat.reload(true);
      setTimeout(cb, 100);
    }
};


export { isAuth }