import React, { useState } from 'react';
import { ApolloClient, ApolloError, gql, useApolloClient, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import './signin.scss';
import { isAuth } from '../share/auth';

const SIGN_IN = gql`
  mutation SignIn($input: String!) {
    signin(input: $input){
      token
      type
      issued
      expires
    }
  }`;

const SignIn: React.FC = (props) => {
  let history = useHistory();
  let location = useLocation();
  let input: any;
  let { from }: any = location.state || { from: { pathname: '/portal' } };
  const [inputValue, setInputValue] = useState<string>("");
  const [signin, { loading: mutationLoading, error: mutationError, data }] = useMutation(SIGN_IN,
    {
      onCompleted (d) {
        const llStorage = window.localStorage;
        llStorage.setItem('atpe', JSON.stringify(d.signin) as string);
        isAuth.authenticate(() => {
          history.replace(from);
        });
      },
      onError (error: ApolloError) {
        console.log(error);
      }
  });

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    signin({ variables: { input: input.value } });
    //input.value = '';
  };

  return (
  <div className="Aligner">
    <div className="signin Aligner-item">
      <h1 className="App-title">Facturación electrónica</h1>
      <div className="App-box box-shadow-medium">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-group-header">
              <label>Cédula, ruc o pasaporte</label>
            </div>
            <div className="bg-gray-dark rounded-2">
              <input ref={ node => { input = node; }} className="input-dark width-full rounded-2 input-lg" type="text" />
            </div>
            {mutationError && <p className="note error">No existe la cédula, ruc o pasaporte ingresado.<br/>Verifica e intenta de nuevo.</p>}
          </div>
          <div className="form-actions">
            <button className="btn btn-block btn-1 mb-2" type="submit">Enviar</button>
          </div>
        </form>
      </div>
      <div className="App-box App-box-helper mt-4">
        {/* {mutationLoading && <p>Loading...</p>} */}
        Algún problema? <a href="mailto:info@poscye.com?subject=Portal de facturacion electronica&body=Estimados, tengo una consulta sobre:">contáctanos.</a>
      </div>
    </div>
  </div>
  );
}

export default SignIn;
