import React, { useState } from 'react';
import './portal.scss';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { ICustomer, IInvoice } from '../share/interface';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { isAuth } from '../share/auth';
import { useHistory } from 'react-router-dom';

import * as multiDownload from 'multi-download';

const GET_CUSTOMER = gql`
  query Customer($date: String, $take: Int, $skip: Int) {
    customer {
      id
      name
      invoiceSum
      withholdingSum
      invoices(date: $date, take: $take, skip: $skip) {
        file
        date
        total
      }
      withholdings(date: $date, take: $take, skip: $skip) {
        file
        date
        total
      }
    }
  }
`;

const Portal: React.FC = () => {
  let history = useHistory();
  let cs: ICustomer = {id: "", name: "", invoiceSum: 0, withholdingSum: 0, invoices: [], withholdings:[]};
  let now: Date = new Date();
  let months: any[] = [];
  let ddt: string = format(new Date(), 'yyyy-MM-01', {locale: es});
  let [date, setDate] = useState(format(new Date(), 'yyyy-MM-01', {locale: es}));
  const [customer, setCustomer] = useState(cs);
  const [page, setPage] = useState(1);
  const [isInvoice, setIsInvoice] = useState(true);
  const [view, setView] = useState(5);
  const [skip, setSkip] = useState(0);
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER,
  {
    variables: {
      date: date,
      take: view,
      skip: skip
    },
    onCompleted (d) {
      setCustomer(d.customer);
    },
    onError (error: ApolloError) {
    }
  });

  const changeUdata = (n: boolean) => {
    setIsInvoice(n);
    setPage(1);
    setSkip(0);
  }

  const setMonthsView = () => {
    for (let i=0; i<=11; i++) {
      months.push({ label: format(now, 'MMM yyyy', {locale: es}), date: format(now, 'yyyy-MM-01', {locale: es})});
      now.setMonth(now.getMonth() - 1);
    }
    months = months.map((p) =>
      <option key={p.date} value={p.date}>{p.label}</option>
    );
  }

  const changeViewNumber = (n: string) => {
    setView(parseInt(n, 10));
    setPage(1);
    setSkip(0);
    refetch({
      date: date,
      take: view,
      skip: skip
    });
  };

  const changePageNumber = (n: number) => {
    if (page+n >= 1 && page+n <= ((isInvoice) ? Math.round(customer.invoiceSum/view) : Math.round(customer.withholdingSum/view))) {
      setPage(page+n);
      setSkip(view * (page+n-1));
      refetch({
        date: date,
        take: view,
        skip: skip
      });
    }
  };

  const endPagination = (n: number) => {
    return (page+n >= 1 && page+n <= ((isInvoice) ? Math.round(customer.invoiceSum/view) : Math.round(customer.withholdingSum/view))) ? false : true;
  };

  const changeDate = (n: string) => {
    setDate(n);
    refetch({
      date: date,
      take: view,
      skip: skip
    });
  };

  const downloadFiles = (fileName: string) => {
    const dm = 'https://la-diferencia-uio.poscye.com/invoices/';
    const urls = [`${dm}${fileName}.pdf`, `${dm}${fileName}.xml`];
    multiDownload(urls);
  };

  setMonthsView();

  return (
    <div className="portal">
      <header className="header d-inline-flex flex-justify-between flex-items-center">
        <img src="assets/logo-blanco.png" alt="La Diferencia" className="company-logo"/>
        <a className="btn header-btn" role="button" onClick={() => {
          isAuth.signout(() => history.push("/"));
        }}><svg xmlns="http://www.w3.org/2000/svg" className="v-align-middle exit mr-1" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" fill="#ffffff" d="M2 2.75C2 1.784 2.784 1 3.75 1h2.5a.75.75 0 010 1.5h-2.5a.25.25 0 00-.25.25v10.5c0 .138.112.25.25.25h2.5a.75.75 0 010 1.5h-2.5A1.75 1.75 0 012 13.25V2.75zm10.44 4.5H6.75a.75.75 0 000 1.5h5.69l-1.97 1.97a.75.75 0 101.06 1.06l3.25-3.25a.75.75 0 000-1.06l-3.25-3.25a.75.75 0 10-1.06 1.06l1.97 1.97z"></path></svg> Salir</a>
      </header>
      <section className="width-full pt-3 d-inline-flex flex-justify-center">
        <div className="BtnGroup">
          <button onClick={e => changeUdata(true)} className={`btn BtnGroup-item portal-btn ${(isInvoice) ? "active" : ""}`} type="button">
            Facturas
          </button>
          <button onClick={e => changeUdata(false)} className={`btn BtnGroup-item portal-btn ${(!isInvoice) ? "active" : ""}`} type="button">
            Retenciones
          </button>
        </div>
      </section>
      <div className="d-inline-flex flex-justify-between width-full">
        <div className="user-info mb-0 pb-0">
          <p className="mb-0">
            <span className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="v-align-middle mb-1" width="14" height="14"><path fillRule="evenodd" fill="#4C82FC" d="M9.585.52a2.678 2.678 0 00-3.17 0l-.928.68a1.178 1.178 0 01-.518.215L3.83 1.59a2.678 2.678 0 00-2.24 2.24l-.175 1.14a1.178 1.178 0 01-.215.518l-.68.928a2.678 2.678 0 000 3.17l.68.928c.113.153.186.33.215.518l.175 1.138a2.678 2.678 0 002.24 2.24l1.138.175c.187.029.365.102.518.215l.928.68a2.678 2.678 0 003.17 0l.928-.68a1.17 1.17 0 01.518-.215l1.138-.175a2.678 2.678 0 002.241-2.241l.175-1.138c.029-.187.102-.365.215-.518l.68-.928a2.678 2.678 0 000-3.17l-.68-.928a1.179 1.179 0 01-.215-.518L14.41 3.83a2.678 2.678 0 00-2.24-2.24l-1.138-.175a1.179 1.179 0 01-.518-.215L9.585.52zM7.303 1.728c.415-.305.98-.305 1.394 0l.928.68c.348.256.752.423 1.18.489l1.136.174c.51.078.909.478.987.987l.174 1.137c.066.427.233.831.489 1.18l.68.927c.305.415.305.98 0 1.394l-.68.928a2.678 2.678 0 00-.489 1.18l-.174 1.136a1.178 1.178 0 01-.987.987l-1.137.174a2.678 2.678 0 00-1.18.489l-.927.68c-.415.305-.98.305-1.394 0l-.928-.68a2.678 2.678 0 00-1.18-.489l-1.136-.174a1.178 1.178 0 01-.987-.987l-.174-1.137a2.678 2.678 0 00-.489-1.18l-.68-.927a1.178 1.178 0 010-1.394l.68-.928c.256-.348.423-.752.489-1.18l.174-1.136c.078-.51.478-.909.987-.987l1.137-.174a2.678 2.678 0 001.18-.489l.927-.68zM11.28 6.78a.75.75 0 00-1.06-1.06L7 8.94 5.78 7.72a.75.75 0 00-1.06 1.06l1.75 1.75a.75.75 0 001.06 0l3.75-3.75z"></path></svg></span>
            <span>{customer.name} <small>{customer.id}</small></span>
          </p>
        </div>
        <div className="user-info mb-0 pb-0">
          <p className="mb-0">
            <strong>Fecha: </strong>
            <select onChange={ e => changeDate(e.target.value) } className="form-select mr-2" aria-label="Important decision">
              {months}
            </select>
            <strong>Ver: </strong>
            <select value={view} onChange={ e => changeViewNumber(e.target.value) } className="form-select" aria-label="Important decision" >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </p>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="th-doc">#</th>
            <th scope="col" className="th-date">Fecha</th>
            <th scope="col" className="th-price">Monto</th>
            <th scope="col" className="th-download">Descarga</th>
          </tr>
        </thead>
        <tbody>
          {
            (isInvoice) ? customer.invoices.map((p: IInvoice, i: number) =>
            <tr key={p.file+i}>
              <td scope="row" className="td-doc">
                <svg xmlns="http://www.w3.org/2000/svg" className="v-align-middle mr-3" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" fill="#6A737D" d="M3.75 1.5a.25.25 0 00-.25.25v11.5c0 .138.112.25.25.25h8.5a.25.25 0 00.25-.25V6H9.75A1.75 1.75 0 018 4.25V1.5H3.75zm5.75.56v2.19c0 .138.112.25.25.25h2.19L9.5 2.06zM2 1.75C2 .784 2.784 0 3.75 0h5.086c.464 0 .909.184 1.237.513l3.414 3.414c.329.328.513.773.513 1.237v8.086A1.75 1.75 0 0112.25 15h-8.5A1.75 1.75 0 012 13.25V1.75z"></path></svg> {p.file}
              </td>
              <td className="td-date">{p.date}</td>
              <td className="td-price">{p.total}</td>
              <td className="td-download" onClick={e => downloadFiles(p.file)}><svg xmlns="http://www.w3.org/2000/svg" className="v-align-middle mr-4" width="16" height="14" viewBox="0 0 16 14"><path id="cloud-download" transform="translate(0 -1)" fill="#4C82FC" fillRule="evenodd" d="M9,12h2L8,15,5,12H7V7H9Zm3-8c0-.44-.91-3-4.5-3C5.08,1,3,2.92,3,5A2.9,2.9,0,0,0,0,8a2.866,2.866,0,0,0,3,3H6V9.7H3A1.6,1.6,0,0,1,1.3,8,1.627,1.627,0,0,1,3,6.3H4.3V5c0-1.39,1.56-2.7,3.2-2.7,2.55,0,3.13,1.55,3.2,1.8V5.3H12c.81,0,2.7.22,2.7,2.2,0,2.09-2.25,2.2-2.7,2.2H10V11h2c2.08,0,4-1.16,4-3.5C16,5.06,14.08,4,12,4Z" /></svg></td>
            </tr>
            ) : customer.withholdings.map((p: IInvoice, i: number) => <tr key={p.file+i}>
              <td scope="row" className="td-doc">
                <svg xmlns="http://www.w3.org/2000/svg" className="v-align-middle mr-3" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" fill="#6A737D" d="M3.75 1.5a.25.25 0 00-.25.25v11.5c0 .138.112.25.25.25h8.5a.25.25 0 00.25-.25V6H9.75A1.75 1.75 0 018 4.25V1.5H3.75zm5.75.56v2.19c0 .138.112.25.25.25h2.19L9.5 2.06zM2 1.75C2 .784 2.784 0 3.75 0h5.086c.464 0 .909.184 1.237.513l3.414 3.414c.329.328.513.773.513 1.237v8.086A1.75 1.75 0 0112.25 15h-8.5A1.75 1.75 0 012 13.25V1.75z"></path></svg> {p.file}
              </td>
              <td className="td-date">{p.date}</td>
              <td className="td-price">{p.total}</td>
              <td className="td-download" onClick={e => downloadFiles(p.file)}><svg xmlns="http://www.w3.org/2000/svg" className="v-align-middle mr-4" width="16" height="14" viewBox="0 0 16 14"><path id="cloud-download" transform="translate(0 -1)" fill="#4C82FC" fillRule="evenodd" d="M9,12h2L8,15,5,12H7V7H9Zm3-8c0-.44-.91-3-4.5-3C5.08,1,3,2.92,3,5A2.9,2.9,0,0,0,0,8a2.866,2.866,0,0,0,3,3H6V9.7H3A1.6,1.6,0,0,1,1.3,8,1.627,1.627,0,0,1,3,6.3H4.3V5c0-1.39,1.56-2.7,3.2-2.7,2.55,0,3.13,1.55,3.2,1.8V5.3H12c.81,0,2.7.22,2.7,2.2,0,2.09-2.25,2.2-2.7,2.2H10V11h2c2.08,0,4-1.16,4-3.5C16,5.06,14.08,4,12,4Z" /></svg></td>
            </tr>
            )
          }
        </tbody>
      </table>
      <nav className="paginate-container" aria-label="Pagination">
        <div className="pagination">
          <a onClick={ e => changePageNumber(-1) } className="previous_page" rel="previous" aria-label="Pagina Anterior" aria-disabled={endPagination(-1)}>Anterior</a>
          <span className="text-white text-emphasized">{page}/{(isInvoice) ? Math.round(customer.invoiceSum/view) : Math.round(customer.withholdingSum/view)}</span>
          <a onClick={ e => changePageNumber(1) } className="next_page" rel="next" aria-label="Pagina Siguiente" aria-disabled={endPagination(1)}>Siguiente</a>
        </div>
      </nav>
    </div>
  )
};

export default Portal;
